<template>
    <div id="cont_Main" class="">
        <div class="main-content 
                    flex-1 
                    bg-gray-100 
                    mt-12 md:mt-12">
            <div class="bg-gray-800 pt-3">
                <div
                    class="
                    rounded-tl-3xl
                    bg-gradient-to-r
                    from-blue-900
                    to-gray-800
                    p-4
                    shadow
                    text-2xl text-white
                    "
                >
                <div v-if="infoAgentesWolkvoxIdCampActiva !== undefined">
                    <div v-if="infoAgentesWolkvoxIdCampActiva !== 0" class="absolute block transform-gpu transition ease-in-out hover:scale-105 duration-300">
                        <ul class="list-reset cont_Camp-Wolkvox">
                            <li class="Camp-Wolkvox font-sans">
                                <img src="/img/Wolkvox_Agent_Icon.png" alt="">
                                <span># {{ infoAgentesWolkvoxIdCampActiva }}</span>
                            </li>
                        </ul>      
                    </div> 
                </div>
                <h3 class=" relative inline-block font-bold pl-2 font-sans">{{ "Gestionar Nueva Sin Pedido"}}  </h3>
                </div>
            </div>
            <div id="cont_ppal" class="w-full md:w-10/12 mx-auto p-6 bg-white shadow-xl rounded-b-md z-10">
                <div id="cont_id" 
                    class="grid 
                            grid-cols-1
                            md:grid-cols-6 
                            gap-2
                            md:gap-6">
                    <div id="cont_Cedula" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Cédula: </span> 
                        <input type="number" 
                            id="Cedula" 
                            v-model="Cedula"
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Cedula Cliente" disabled>
                    </div>
                    <div id="cont_Nombre" 
                        class="relative 
                                inline-block 
                                md:col-span-2">
                        <span class=" relative block w-full text-left">Nombre:</span> 
                        <input type="text" 
                            id="NombreCliente" 
                            v-model="NombreCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Completo" 
                            disabled>
                    </div>
                    <div id="cont_TipoFacturacion" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Tipo Facturacion:</span> 
                        <input type="text" 
                            id="TipoFacturacion" 
                            v-model="tipoFacturacion" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Tipo Facturacion" 
                            disabled>
                    </div>
                    <div id="cont_CorteZona" 
                        class="relative 
                                inline-block
                                grid
                                grid-cols-2
                                gap-6"> 
                        <div id="cont_Zona">
                            <span class=" relative block w-full text-left">Zona:</span> 
                            <input type="number" 
                                id="ZonaCliente" 
                                v-model="ZonaCliente" 
                                class="w-full 
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md" 
                                placeholder="Zona" disabled>
                        </div>
                        <div id="cont_Corte">
                            <span class=" relative block w-full text-left">Corte:</span> 
                            <input type="text" 
                                id="CorteCliente" 
                                v-model="CorteCliente" 
                                class="w-full 
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md" 
                                placeholder="Corte" disabled>
                        </div>
                    </div>
                    <div id="cont_IDCliente" 
                    class="relative 
                            inline-block">
                        <span class=" relative block w-full text-left">ID Cliente:</span> 
                            <div class=" grid grid-cols-4 gap-2">
                                <input type="text" 
                                    id="IDCliente" 
                                    v-model="idCliente" 
                                    class="w-full 
                                            border-solid 
                                            border-gray-600 
                                            border-b-2
                                            rounded-md
                                            col-span-3" 
                                    placeholder="ID Cliente" 
                                    disabled>
                                <button id="btn_Historico"
                                        class="flex
                                            w-full
                                            bg-blue-600
                                            rounded-lg
                                            border-solid
                                            border-blue-500
                                            border-b-2
                                            text-white
                                            font-bold
                                            col-span-1"
                                        @click="abrirInfoCliente()">
                                    <i class="fas fa-address-book mx-auto my-auto"></i>
                                </button>
                            </div>
                    </div>
                </div>
                <div id="cont_nav" 
                    class="my-4">
                    <div id="nav_header"
                        class="relative block mx-auto grid grid-cols-1 gap-6  px-0 md:px-16 md:grid-cols-2">
                        <div id="cont_BotonNuevoComp" class=" relative inline-block w-full p-2">
                            <button id="btn_NuevaGestion"
                                    class="flex 
                                        w-full
                                        bg-green-600
                                        rounded-lg
                                        border-solid
                                        border-green-500
                                        border-b-2
                                        p-2
                                        text-white
                                        font-bold"
                                    @click="NuevaGestion()">
                                <i class="fas fa-calendar-plus my-auto mx-3"></i> <span class="mx-auto">Añadir Gestion</span> <i class="w-5 my-auto"></i>
                            </button>
                        </div>
                        <div id="cont_BotonAbrirGrid" class=" relative inline-block w-full p-2">
                            <button id="btn_Historico"
                                    class="flex 
                                        w-full
                                        bg-blue-600
                                        rounded-lg
                                        border-solid
                                        border-blue-500
                                        border-b-2
                                        p-2
                                        text-white
                                        font-bold"
                                    @click="abrirGrid()">
                                <i class="fas fa-table my-auto mx-3 "></i> <span class="mx-auto">Gestiones Realizadas</span> <i class="w-5 my-auto"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="cont_contenido"
                    v-if="Contenido == true" class="relative w-full">
                    <NuevaGestion v-show="Nuevo == true" :idCliente="idCliente" :Cedula="Cedula" :idTipoMotivo="idTipoMotivo" />
                    <GridGestiones v-show="Grid == true" :idCliente="idCliente"/>
                </div>
            </div>
            <div class=" fixed block top-0" v-if="modalChatObservCompNuevasSinPedido == true">
                <ChatObservaciones class="z-50"/>
            </div>
            <div class=" fixed block top-0" v-if="modalInfoCliente == true">
                <InfoCliente class="z-50" :InformacionCliente="InformacionCliente"/>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "@/logic/auth";
import { mapState, mapMutations } from 'vuex'
import axios from "axios";
import NuevaGestion from '../../components/NuevasSinPedido/NuevaGestion.vue'
import GridGestiones from '../../components/NuevasSinPedido/GridGestiones.vue'
import ChatObservaciones from '../../components/NuevasSinPedido/ChatObservaciones.vue'
import InfoCliente from '../../components/NuevasSinPedido/InfoCliente.vue'

export default{
    components: {
        NuevaGestion,
        GridGestiones,
        ChatObservaciones,
        InfoCliente
    },
    data(){
        return {
            Contenido: true,

            Nuevo: false,
            Grid: false,

            idCliente: 0,
            idTipoMotivo: 0,
            Cedula: "",
            NombreCliente: "",
            tipoFacturacion: "",
            ZonaCliente: "",
            CorteCliente: "",
            idCliente: "",

            campañaActual: "",

            actividadCliente: "",
            clasificacionCliente: "",
            cupoCliente: "",
            devolucionCliente: "",
            facturadoCliente: "",
            pagosCliente: "",
            saldoCliente: "",

            InformacionCliente: {},
        }
    },
    methods: {
        getCliente(idCliente) {
            //this.Contenido = true
            this.InformacionCliente = this.NuevasSinPedido.filter((el) => {return el.idCliente == idCliente})[0]
            this.CorteCliente = this.InformacionCliente.corte
            this.ZonaCliente = this.InformacionCliente.zona
            this.Cedula = this.InformacionCliente.cedula
            this.NombreCliente = this.InformacionCliente.nombre
            this.tipoFacturacion = this.InformacionCliente.tipoFacturacion
            this.closeModalCargando()
        },
        NuevaGestion(){
            if(this.Contenido == false){
                this.getCliente()
            }
            this.Grid = false
            this.Nuevo = true
        },
        abrirGrid(){
            if(this.Contenido == false){
                this.getCliente()
            }
            this.openModalCargando()
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/EncGestionNuevasSinPedido/GetEncGestionNuevasSinPedidoSP/" + this.idCliente, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.setEncGestionNuevasSinPedido(result.data)
                    this.closeModalCargando()
                })
                .catch(err => {
                    console.log(err)
                    this.closeModalCargando()
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
            this.Nuevo = false
            this.Grid = true
        },
        abrirInfoCliente(){
            this.openModalInfoCliente(this.InformacionCliente)
        },
        ...mapMutations([
            "loadNavbar", 
            "hiddenNavbar", 
            "logueo", 
            "openNavNuevasSinPedido",
            "openModalInfoCliente",
            "openModalCargando",
            "closeModalCargando",
            "setEncGestionNuevasSinPedido"
        ]),
    },
    created() {
        this.openModalCargando()
        this.idCliente = this.$route.params.idCliente;
        this.idTipoMotivo = this.$route.params.idTipoMotivo;
        this.getCliente(this.idCliente)
        this.openNavNuevasSinPedido()
        try {
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            if (user.token == "" || user.token == undefined) {
                this.$alert(
                    "Por favor vuelve a ingresar a la plataforma",
                    "Expiro la Sesion!",
                    "error"
                );
                this.$router.push("/");
            } else {
                this.display = "block";
                this.usuario = user.usuario;
                this.idUsuarioAux = user.id;
                this.loadNavbar(user);
            }
        } catch (err) {
            this.$alert(
                "Por favor vuelve a ingresar a la plataforma",
                "Expiro la Sesion!",
                "error"
            );
            this.$router.push("/");
        }
    },
    computed: {
        ...mapState(["modalChatObservCompNuevasSinPedido","modalInfoCliente","infoAgentesWolkvox","EncGestionNuevasSinPedido","NuevasSinPedido"]),
        infoAgentesWolkvoxIdCampActiva(){
            if(this.infoAgentesWolkvox == undefined){
                return undefined
            }else{
                return this.infoAgentesWolkvox.idCampActiva
            }
        }
    }
}
</script>

<style>
*:focus {
    outline: 0 !important;
}
input {
    padding: 2px;
}
button{
    transition: 0.2s ease-in-out;
}
button:hover{
    transform: scale(1.05);
}
.cont_Camp-Wolkvox {
    top: -0.5rem;
    position: relative;
}
.Camp-Wolkvox {
    display: flex;
    background: #f2950b;
    color: #000;
    border-radius: 1rem;
    padding: 0.6rem;
    font-size: 1.1rem;
    font-weight: 500;
    position: relative;
    align-items: center;
    cursor: not-allowed;
}
.Camp-Wolkvox img {
    width: 2rem;
    filter: invert(1);
    margin-right: 0.5rem;
}
</style>