<template>
    <div id="cont_Main">
        <div id="cont_NuevaGestion" class="grid grid-cols-1 md:grid-cols-4 gap-6">
            <div id="cont_numFactura">
                <span class=" relative block w-full text-left">Motivos Corbanza: *</span> 
                <select
                    id="buscarzona"
                    v-model="Motivo"
                    class="border rounded-xl shadow h-9 bg-white w-full"
                >
                    <option disabled>Motivos</option>
                    <option v-for="motivo in MotivosLlamadasNuevasSinPedido" :value="motivo" :key="motivo.numCodigo">
                        {{ motivo.descripcionMotivo }}
                    </option>
                </select>
            </div>
            <div id="cont_numFactura">
                <span class=" relative block w-full text-left">Campaña Gestion: *</span> 
                <select
                    id="buscarzona"
                    v-model="CampañaGestion"
                    class="border rounded-xl shadow h-9 bg-white w-full"
                >
                    <option disabled>Campaña Gestion</option>
                    <option v-for="campaña in CampañasNuevasSinPedido" :value="campaña.campaña" :key="campaña.campaña">
                        {{ campaña.campaña }}
                    </option>
                </select>
            </div>
            <div id="cont_Observacion" class="md:col-span-2">
                <span class=" relative block w-full text-left">Observacion: *</span> 
                <textarea type="text" 
                    id="Observacion" 
                    v-model="observacion" 
                    class="w-full
                            border-solid 
                            border-gray-600 
                            border-b-2
                            rounded-md" 
                    placeholder="Observacion"
                    rows="1" ></textarea>
            </div>
        </div>
        <div id="cont_VolverALLamar" class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div id="cont_VolverALlamar" class="my-4">
                <input
                    id="VolverALlamar"
                    name="VolverALlamar"
                    v-model="VolverALlamar"
                    type="checkbox"
                    class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded inline-block"
                />
                <label for="remember_me" class="ml-2 inline-block">
                    Volver a llamar?
                </label>
            </div>
            <div id="cont_FechaGestion" v-if="VolverALlamar">
                <span class=" relative block w-full text-left">Fecha Proxima Llamada: *</span> 
                <input type="date" 
                    id="FechaGestion" 
                    v-model="fechaProximaLlamada" 
                    class="w-full
                            border-solid 
                            border-gray-600 
                            border-b-2
                            rounded-md"
                >
            </div>
        </div>
        <div class="cont_btns">
            <div v-if="infoAgentesWolkvoxIdCampActiva !== undefined">
                <button id="btn_cargarTipificacionWolkvox"
                        v-if="infoAgentesWolkvoxIdCampActiva !== 0"
                        class="bg-green-600
                            rounded-lg
                            p-2
                            text-white
                            font-bold
                            mt-4"
                        @click="cargarTipificacionWolkvox()">
                    <i><img src="/img/BuscarWolkvox.png" alt=""></i>
                </button>
            </div>
            <button id="btn_guardarGestion"
                    class="bg-green-600
                        rounded-lg
                        border-solid
                        border-green-500
                        border-b-2
                        p-2
                        text-white
                        font-bold
                        mt-4"
                    @click="guardarGestion()">
                <i class="fas fa-save"></i> Guardar Gestion
            </button>
        </div>
    </div>
</template>

<script>
import auth from "@/logic/auth"
import { mapMutations, mapState } from 'vuex'
export default {
    name: 'NuevaGestion',
    props: {
        idCliente: 0,
        Cedula: 0,
        idTipoMotivo: 0,
    },
    data(){
        return{
            fechaProximaLlamada: "",
            Motivo: {},
            CampañaGestion: "",
            observacion: "",
            VolverALlamar: false,

            MotivosLlamadasNuevasSinPedido: [],
            CampañasNuevasSinPedido: [],

            enviar: false,
            mostrarBtnCargarTipificacionWolkvox: false,
            
            EncGestion: {},
            idGestion: 0,
        }
    },
    methods: {
        ...mapMutations(["openModalCargando","closeModalCargando"]),
        getDataLlamadasNuevasSinPedido(){
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            if (user.token == "" || user.token == undefined) {
                this.$router.push("/");
            } else {
                this.display = "block";
                this.usuario = user.usuario;
                this.idUsuarioAux = user.id;
                axios
                    .get(this.$hostname + "/Api/MotivosLlamadasNuevasSinPedido/GetMotivosLlamadasNuevasSinPedidoSP/" + this.idTipoMotivo, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + user.token,
                            "Access-Control-Allow-Origin": "*",
                        },
                    })
                    .then((result) => {
                        this.MotivosLlamadasNuevasSinPedido = result.data
                        axios
                            .get(this.$hostname + "/Api/Clientes/GetCampañasGestionNuevasSinPedidoSP", {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": "Bearer " + user.token,
                                    "Access-Control-Allow-Origin": "*",
                                },
                            })
                            .then((result) => {
                                this.CampañasNuevasSinPedido = result.data
                                this.closeModalCargando()
                            })
                            .catch((err) => {
                                console.log(err);
                                this.closeModalCargando()
                                this.$alert(
                                    "Por favor vuelve a ingresar a la plataforma",
                                    "Expiro la Sesion!",
                                    "error"
                                );
                                this.$router.push("/");
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.closeModalCargando()
                        this.$alert(
                            "Por favor vuelve a ingresar a la plataforma",
                            "Expiro la Sesion!",
                            "error"
                        );
                        this.$router.push("/");
                    });
            }
        },
        guardarGestion(){
            if(!this.VolverALlamar){
                if(this.Motivo == {} || this.Motivo == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa un Motivo para guardar la gestion",
                        "Ingresa un Motivo!",
                        "error"
                    );
                }else{
                    if(this.CampañaGestion == "" || this.CampañaGestion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Campaña para guardar la gestion",
                            "Ingresa una Campaña!",
                            "error"
                        );
                    }else{
                        if(this.observacion ==  "" || this.observacion == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Observacion para guardar la gestion",
                                "Ingresa una Observacion!",
                                "error"
                            );
                        }else{
                            this.enviar = true
                        }
                    }
                }
                if(this.CampañaGestion == "" || this.CampañaGestion == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Campaña para guardar la gestion",
                        "Ingresa una Campaña!",
                        "error"
                    );
                }else{
                    if(this.observacion ==  "" || this.observacion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Observacion para guardar la gestion",
                            "Ingresa una Observacion!",
                            "error"
                        );
                    }else{
                        if(this.Motivo == {} || this.Motivo == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa un Motivo para guardar la gestion",
                                "Ingresa un Motivo!",
                                "error"
                            );
                        }else{
                            this.enviar = true
                        }
                    }
                }
                if(this.observacion ==  "" || this.observacion == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Observacion para guardar la gestion",
                        "Ingresa una Observacion!",
                        "error"
                    );
                }else{
                    if(this.Motivo == {} || this.Motivo == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa un Motivo para guardar la gestion",
                            "Ingresa un Motivo!",
                            "error"
                        );
                    }else{
                        if(this.CampañaGestion == "" || this.CampañaGestion == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Campaña para guardar la gestion",
                                "Ingresa una Campaña!",
                                "error"
                            );
                        }else{
                            this.enviar = true
                        }
                    }
                }
            }
            if (this.VolverALlamar){
                if(this.Motivo == {} || this.Motivo == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa un Motivo para guardar el compromiso",
                        "Ingresa un Motivo!",
                        "error"
                    );
                }else{
                    if(this.CampañaGestion == "" || this.CampañaGestion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Campaña para guardar el compromiso",
                            "Ingresa una Campaña!",
                            "error"
                        );
                    }else{
                        if(this.observacion ==  "" || this.observacion == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Observacion para guardar el compromiso",
                                "Ingresa una Observacion!",
                                "error"
                            );
                        }else{
                            if(this.fechaProximaLlamada ==  "" || this.fechaProximaLlamada == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa una Fecha de Proxima Llamada",
                                    "Ingresa una Fecha!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
                if(this.CampañaGestion == "" || this.CampañaGestion == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Campaña para guardar el compromiso",
                        "Ingresa una Campaña!",
                        "error"
                    );
                }else{
                    if(this.observacion ==  "" || this.observacion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Observacion para guardar el compromiso",
                            "Ingresa una Observacion!",
                            "error"
                        );
                    }else{
                        if(this.fechaProximaLlamada ==  "" || this.fechaProximaLlamada == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Fecha para guardar el compromiso",
                                "Ingresa una Fecha!",
                                "error"
                            );
                        }else{
                            if(this.Motivo == {} || this.Motivo == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa un Motivo para guardar el compromiso",
                                    "Ingresa un Motivo!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
                if(this.observacion ==  "" || this.observacion == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Observacion para guardar el compromiso",
                        "Ingresa una Observacion!",
                        "error"
                    );
                }else{
                    if(this.fechaProximaLlamada ==  "" || this.fechaProximaLlamada == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Fecha para guardar el compromiso",
                            "Ingresa una Fecha!",
                            "error"
                        );
                    }else{
                        if(this.Motivo == {} || this.Motivo == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa un Motivo para guardar el compromiso",
                                "Ingresa un Motivo!",
                                "error"
                            );
                        }else{
                            if(this.CampañaGestion == "" || this.CampañaGestion == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa una Campaña para guardar el compromiso",
                                    "Ingresa una Campaña!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
                if(this.fechaProximaLlamada ==  "" || this.fechaProximaLlamada == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Fecha para guardar el compromiso",
                        "Ingresa una Fecha!",
                        "error"
                    );
                }else{
                    if(this.observacion ==  "" || this.observacion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Observacion para guardar el compromiso",
                            "Ingresa una Observacion!",
                            "error"
                        );
                    }else{
                        if(this.fechaProximaLlamada ==  "" || this.fechaProximaLlamada == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Fecha para guardar el compromiso",
                                "Ingresa una Fecha!",
                                "error"
                            );
                        }else{
                            if(this.Motivo == {} || this.Motivo == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa un Motivo para guardar el compromiso",
                                    "Ingresa un Motivo!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
            }
            if(this.enviar){
                this.enviarGestion()
            }
        },
        enviarGestion(){
            this.openModalCargando()
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            //console.log(user)
            if (this.VolverALlamar){
                this.EncGestion = {
                    idCliente: this.idCliente,
                    fechaCreacion: new Date(),
                    usuarioCreacion: user.loginUsuario,
                    campañaCreacion: this.CampañasNuevasSinPedido[0].campaña,
                    campañaGestion: this.CampañaGestion,
                    idMotivo: this.Motivo.numCodigo,
                    LlamarDeNuevo: true,
                    fechaProximaLlamada: new Date(this.fechaProximaLlamada)
                }
            } else {
                this.EncGestion = {
                    idCliente: this.idCliente,
                    fechaCreacion: new Date(),
                    usuarioCreacion: user.loginUsuario,
                    campañaCreacion: this.CampañasNuevasSinPedido[0].campaña,
                    campañaGestion: this.CampañaGestion,
                    idMotivo: this.Motivo.numCodigo,
                    LlamarDeNuevo: false,
                    fechaProximaLlamada: new Date("1/1/1900 00:00:00"),
                }
            }
            axios
                .post(this.$hostname + "/Api/EncGestionNuevasSinPedido", this.EncGestion, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.idGestion = result.data.data.numCodigo
                    this.DetGestion = {
                        idGestion: this.idGestion,
                        fechaIngreso: new Date(),
                        usuarioIngreso: user.loginUsuario,
                        observacion: this.observacion,
                        esCartera: true,
                    }
                    axios
                        .post(this.$hostname + "/Api/DetGestionNuevasSinPedido", this.DetGestion, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.token,
                            },
                        })
                        .then((result) => {
                            this.closeModalCargando()
                            this.Motivo = {}
                            this.CampañaGestion= ""
                            this.observacion = ""
                            this.fechaProximaLlamada = ""
                            this.VolverALlamar = false
                            this.$alert(
                                "Se grabó la nueva gestion para la fecha " + this.fechaProximaLlamada + " con el siguiente ID: #" + this.idGestion,
                                "Éxito!",
                                "success"
                            )
                        })
                        .catch((err) => {
                            this.closeModalCargando()
                            console.log(err)
                            this.$alert(
                                "Por favor vuelve a ingresar a la plataforma",
                                "Expiro la Sesion!",
                                "error"
                            );
                        });
                })
                .catch((err) => {
                    this.closeModalCargando()
                    console.log(err)
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                });
            
        },
        cargarTipificacionWolkvox(){
            var user = auth.getUserLogged();
            user = JSON.parse(user);

            this.openModalCargando()
            axios
                .post(this.$hostname + "/api/WolkvoxAPI/cargarTipificacionWolkvox/",
                {
                    token: this.infoAgentesWolkvox.token,
                    numFactura: this.Cedula,
                    idCamp: this.infoAgentesWolkvoxIdCampActiva,
                    idAgente: this.infoAgentesWolkvox.idAgente
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    var continuar = true
                    var resultadosConsulta = result.data
                    
                    resultadosConsulta.forEach((element,index) => {
                        if(element.result != "CLEAN"){
                            resultadosConsulta[index].comments = element.comments.substr(18)
                            resultadosConsulta[index].date = new Date(element.date)
                            //resultadosConsulta[index].recall_date = new Date(element.recall_date)
                            if(element.cod_act != ""){
                                resultadosConsulta[index].ObjMotivo = this.MotivosLlamadasNuevasSinPedido.filter((element2)=>{
                                    return element2.idMotivoTipificacionWolkvox == element.cod_act
                                })[0]
                            }
                        }
                    });
                    console.log(resultadosConsulta)

                    this.Motivo = resultadosConsulta[0].ObjMotivo
                    this.observacion = resultadosConsulta[0].comments
                    
                    this.closeModalCargando()
                })
                .catch((err) => {
                    this.closeModalCargando()
                    console.log(err);
                    this.$alert(
                        "Comunicate con Sistemas",
                        "No se encontro gestion para la factura consultada",
                        "error"
                    );
                    this.$router.push(
                        "/NuevasSinPedido/" + 
                        (this.UbicacionNuevasSinPedido == "Pendientes") 
                            ? this.UbicacionNuevasSinPedido 
                            : (this.UbicacionNuevasSinPedido == "Gestionadas") 
                                ? this.UbicacionNuevasSinPedido 
                                : "Inicio"
                    );
                });
        }
    },
    created() {
        this.openModalCargando()
        this.getDataLlamadasNuevasSinPedido()
    },
    computed: {
        ...mapState(["infoAgentesWolkvox","UbicacionNuevasSinPedido"]),
        infoAgentesWolkvoxIdCampActiva(){
            if(this.infoAgentesWolkvox == undefined){
                return undefined
            }else{
                return this.infoAgentesWolkvox.idCampActiva
            }
        }
    }
}
</script>

<style>
input, textarea {
    padding: 2px;
}
#btn_cargarTipificacionWolkvox {
    position: absolute;
    left: 0rem;
    width: 2.7rem;
    background: #f2950b;
    border-bottom: solid 0.2rem #ffb74a;
}
</style>